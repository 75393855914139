import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/img/decline.svg'
import _imports_1 from '@/assets/img/logo-momi.svg'


const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "darkModalBg" }
const _hoisted_3 = { class: "text-end" }
const _hoisted_4 = { class: "text-center my-5" }
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  class: "w-50 mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exhibition_brand_image = _resolveComponent("exhibition-brand-image")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("img", {
          src: _imports_0,
          class: "pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$opModal.closeLast()))
        })
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.exhibition.hasOwnBrand)
          ? (_openBlock(), _createBlock(_component_exhibition_brand_image, {
              key: 0,
              exhibition: _ctx.exhibition
            }, null, 8, ["exhibition"]))
          : (_openBlock(), _createBlock("img", _hoisted_5))
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}