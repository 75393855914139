
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';

@Options({})
export default class InsertCodeOverlay extends Vue {

    pin: number = null;
    title: string = "";
    exhibition: OM.GetExhibitionVm = StorageServices.getExhibition();

    created() {}

    searchForOpera() {
        if(this.exhibition.hasPinOnArtworks) {
            if(!this.pin)
                return;

            this.$emit('searchForOpera', this.pin.toString());
        }
        else {
            if(!this.title)
                return;

            this.$emit('searchForOperaByTitle', this.title);
        }
    }

}
