import { LocalizationServices } from './services/LocalizationServices';
import * as OM from '@/model';

export let date = function(el, binding, vnode, prevVnode){
    if(!binding.value)
        return;

    if(el.tagName.toLowerCase() != 'input'){
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if(el.getAttribute('type') != 'date'){
        console.error('v-date directive must be used on input with type="date"');
        return;
    }
    let modelDirectiveIndex = el.directives.findIndex(x => x.name == 'model');
    if(modelDirectiveIndex == -1){
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = el.directives[modelDirectiveIndex].value;
    if(val){
        (<HTMLInputElement>el).value = validateDate(val);
        var item = (<HTMLInputElement>el).attributes.getNamedItem("placeholder");
        item.value = "";
        (<HTMLInputElement>el).attributes.setNamedItem(item);
    }
}

function validateDate(inputDate: string){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        return years +  "-" + months + "-" + days;
    }
}

function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}

export let localizedPlaceholderDirective = function (el, binding, vnode, prevVnode) {

    var key = binding.value["key"];
    var value = binding.value["value"];

    if (!key || !value) {
        console.error('v-localizedPlaceholder need key and value');
        return;
    }

    var prevText = binding.value["prevText"];
    var afterText = binding.value["afterText"];

    var text = LocalizationServices.getLocalizedValue(key, value, afterText, prevText);

    (<any>el).placeholder = text;
}

export let autofocus = function (el, binding, vnode, prevVnode) {
    (<HTMLInputElement>el).focus();
}

export let language = function (el, binding, vnode, prevVnode) {

    var userLanguage = LocalizationServices.GetLanguage();
    var keys = Object.keys(binding.value.values);

    if(keys.length == 0){
        return;
    }

    if(keys.indexOf(userLanguage) == -1){
        var key = keys[0];
        userLanguage = key;
    }

    if(!binding.value.values[userLanguage]){
        var tempKey = keys.filter( x => x != userLanguage);
        userLanguage = tempKey[0];
    }

    el.innerHTML = binding.value.values[userLanguage];
}

// }
// Vue.directive('language', {
//     bind: function (el: HTMLElement, binding: VNodeDirective) {
        
//     },
//     unbind: function(el: HTMLElement){
//         let ind = LocalizationServices.labelsToUpdate.findIndex(x => x.el == el);
//         LocalizationServices.labelsToUpdate.splice(ind, 1);
//         // console.log(LocalizationServices.labelsToUpdate);
//     }
// })