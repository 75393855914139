
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({})
export default class ProgressBar extends Vue {

    @Prop() exhibition: OM.GetExhibitionVm;

    created() {}

}
