
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class InfoModal extends Vue {

    @Prop() text: string;
    @Prop() confirm: string;
    @Prop() deny: string;

    @Prop() confirmCb = null;
    @Prop() denyCb = null;

    created() {
    }

}
