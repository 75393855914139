export let baseUrl = process.env.VUE_APP_ENDPOINT;
export let siteUrl = process.env.VUE_APP_WEBSITE;
export let stripeKey = process.env.VUE_APP_STRIPE_KEY;

var tempIceServers = process.env.VUE_APP_ICESERVERS;
export let iceServers = JSON.parse(tempIceServers);

//custom variable set in package.json
export let platform = process.env.VUE_APP_PLATFORM ? process.env.VUE_APP_PLATFORM.trim() : "";
export let debugAnalytics = process.env.VUE_APP_DEBUG.trim() == "true"; 

export const platformList = {
    web: "web",
    android: "android",
    ios: "ios"
}