
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class GetDayOfWeek extends Vue {

    @Prop({
        default: -1
    }) value;

    @Prop({
        default: ""
    }) spanClass: string;

    created() {}
}
