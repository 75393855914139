import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.value == 0)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          class: _ctx.spanClass,
          localizedKey: "app_sunday",
          text: "Sunday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 1)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 1,
          class: _ctx.spanClass,
          localizedKey: "app_monday",
          text: "Monday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 2)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 2,
          class: _ctx.spanClass,
          localizedKey: "app_tuesday",
          text: "Tuesday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 3)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 3,
          class: _ctx.spanClass,
          localizedKey: "app_wednesday",
          text: "Wednesday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 4)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 4,
          class: _ctx.spanClass,
          localizedKey: "app_thursday",
          text: "Thursday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 5)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 5,
          class: _ctx.spanClass,
          localizedKey: "app_friday",
          text: "Friday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.value == 6)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 6,
          class: _ctx.spanClass,
          localizedKey: "app_saturday",
          text: "Saturday"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 64))
}