
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CommentInteractionClient } from '@/services/services';
import ModalContainer from '@/components/modalContainer.vue';
import InfoModal from './infoModal.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        ModalContainer
    }
})
export default class FeedbackNoteModal extends Vue {

    @Prop() itemIdentifier: string;
    @Prop() itemType: string;

    comment: string = "";

    created() {}

    get canSend() {
        return this.comment;
    }

    saveComment() {
        if(this.itemType == "Exhibition") {
            var exhibitionModel = new OM.ExhibitionCommentInteractionVM();
            exhibitionModel.exhibitionIdentifier = store.state.exhibition.identifier;
            exhibitionModel.comment = new OM.LocalizedText();
            exhibitionModel.comment.text = this.comment;

            CommentInteractionClient.saveExhibitionInteraction(exhibitionModel)
            .then(x => {
                this.successModal();
            })
            .catch(y => {
                this.errorModal(y);
            });
        } 
        else if(this.itemType == "ExhibitionItem") {
            var exhibitionItemModel = new OM.ArtCommentInteractionVM();
            exhibitionItemModel.exhibitionIdentifier = store.state.exhibition.identifier;
            exhibitionItemModel.artIdentifier = this.itemIdentifier;
            exhibitionItemModel.comment = new OM.LocalizedText();
            exhibitionItemModel.comment.text = this.comment;

            CommentInteractionClient.saveArtInteraction(exhibitionItemModel)
            .then(x => {
                this.successModal();
            })
            .catch(y => {
                this.errorModal(y);
            });
        }
        else if(this.itemType == "Author") {
            var authorModel = new OM.AuthorCommentInteractionVM();
            authorModel.exhibitionIdentifier = store.state.exhibition.identifier;
            authorModel.authorIdentifier = this.itemIdentifier;
            authorModel.comment = new OM.LocalizedText();
            authorModel.comment.text = this.comment;

            CommentInteractionClient.saveAuthorInteraction(authorModel)
            .then(x => {
                this.successModal();
            })
            .catch(y => {
                this.errorModal(y);
            });
        }
    }

    successModal() {
        this.$opModal.closeLast();
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_FeedbackSentSuccessfully", "Feedback sent successfully"),
            confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
            confirmCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    errorModal(err: any) {
        this.$opModal.closeLast();
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_somethingWentWrongRetryLater", "Something went wrong, retry later."),
            confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
            confirmCb: () => {
                this.$opModal.closeLast();
            }
        })
    }
}
