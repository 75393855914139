
import { Options, Vue } from 'vue-class-component';
import { PatronageClient } from '@/services/services';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({
    components: {}
})
export default class PatronageBackersModal extends Vue {

    @Prop() patronageIdentifier: string;

    backers: OM.PatronageTransaction[] = [];

    activeTab: string = "";
    sortedByDateUp: boolean = false;
    sortedByAmountUp: boolean = false;

    created() {
        PatronageClient.getAllTransactionsByPatronageId(this.patronageIdentifier)
        .then(x => {
            this.backers = x;
        })
    }
    
    sortDateManagement() {
        this.activeTab = "date";
        this.sortedByDateUp = !this.sortedByDateUp;

        if(this.sortedByDateUp) {
            this.backers = this.backers.sort(function(a, b) {
                return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
            })
        }
        else if(!this.sortedByDateUp) {
            this.backers = this.backers.sort(function(a, b) {
                return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
            })
        }
    }
    sortAmountManagement() {
        this.activeTab = "amount";
        this.sortedByAmountUp = !this.sortedByAmountUp;

        if(this.sortedByAmountUp) {
            this.backers = this.backers.sort(function(a, b) {
                return a.amount - b.amount;
            })
        }
        else if(!this.sortedByAmountUp) {
            this.backers = this.backers.sort(function(a, b) {
                return b.amount - a.amount;
            })
        }
    }

}
