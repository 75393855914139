
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ProgressBar extends Vue {

    @Prop() maxAmount: number;
    @Prop() currentAmount: number;

    rnd: number = 0;

    created() {
        this.rnd = Math.random();
    }

    mounted() {
        setTimeout(() => {
            var percentage = (this.currentAmount * 100) / this.maxAmount;
            var progressBar = document.getElementById("progressBar_" + this.rnd);
            progressBar.style.width = percentage.toString() + "%";
        }, 500);
        
        // this.$nextTick(() => {
            
        // })
    }

}
