

import { Options, Vue } from 'vue-class-component';
import { ModalBus } from './modalBus';
import { ModalInput } from './classes'
import { markRaw } from '@vue/reactivity';

@Options({})
export default class ModalsContainer extends Vue {

    items: ModalInput[] = [];
    transitions: number = 0;

    created(){
        ModalBus.registerAddModalCallback((item, props, closeCallback = null) => {
            this.items.push({
                modalComponent: markRaw(item),
                props: props,
                closeCallback: closeCallback
            })
            document.body.classList.add('__modal-open');
        })
        ModalBus.registerCloseLastModal(this.closeLastModal);
        ModalBus.setModalsArray(this.items);
    }

    hasMouseDown: boolean;
    onDownBg(ev, i){
        if(ev.target == this.$refs['__modalBg' + i]){
            this.hasMouseDown = true;
        }
    }
    onUpBg(ev, i){
        // if(this.hasMouseDown){
        //     this.hasMouseDown = false;
        //     if(ev.target == this.$refs['__modalBg' + i]){
        //         this.closeLastModal();
        //     }
        // }
    }

    closeModal(index){
        let removed = this.items.splice(index, 1)[0];
        if(removed.closeCallback){
            removed.closeCallback();
        }
        if(this.items.length == 0){
            document.body.classList.remove('__modal-open');
        }
    }
    closeFromBg(ev){
        this.closeLastModal();
    }
    closeLastModal(){
        let removed = this.items.pop();
        if(removed.closeCallback){
            removed.closeCallback();
        }
        if(this.items.length == 0){
            document.body.classList.remove('__modal-open');
        }
    }

}
