
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FeedbackNoteModal from './modals/feedbackNoteModal.vue';
import InfoModal from './modals/infoModal.vue';

@Options({})
export default class ModalContainer extends Vue {

    @Prop() itemIdentifier: string;
    @Prop() itemType: string;

    created() {}

    giveFeedback() {
        this.$opModal.show(FeedbackNoteModal, {
            itemIdentifier: this.itemIdentifier,
            itemType: this.itemType
        })
    }

}
