import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "title white text-center d-block px-3 py-3" }
const _hoisted_2 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_container = _resolveComponent("modal-container")

  return (_openBlock(), _createBlock(_component_modal_container, null, {
    default: _withCtx(() => [
      _createVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
      _createVNode("div", _hoisted_2, [
        (_ctx.confirmCb)
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              class: "black w-100 mb-2",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmCb && _ctx.confirmCb(...args)))
            }, [
              _createVNode("span", { innerHTML: _ctx.confirm }, null, 8, ["innerHTML"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.denyCb)
          ? (_openBlock(), _createBlock("button", {
              key: 1,
              class: "transparentWhitebBorder",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.denyCb && _ctx.denyCb(...args)))
            }, [
              _createVNode("span", { innerHTML: _ctx.deny }, null, 8, ["innerHTML"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}