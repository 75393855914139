import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/audioRoom/:exhibitionIdentifier/:roomname',
            name: 'audioRoom',
            component: () => import("./views/audioRoom.vue")
        }
    ];
};