
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {}
})
export default class RegisterLoginModal extends Vue {

    @Prop() text: string;

    created() {}

    closeModalAndNavigate(nextRoute: string) {
        this.$router.push(nextRoute);
        this.$opModal.closeLast();
    }

}
