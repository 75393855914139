
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
    }
})  
export default class GoogleAutocomplete extends Vue {
    
    @Prop() modelValue: any;

    @Prop({
        default: ""
    }) inputClass: string;

    @Prop({
        default: 1
    }) tabindex: number;

    @Prop({
        default: ""
    })  addressLocal: string;

    @Prop({
        default: ""
    }) placeholderKey;
    @Prop({
        default: ""
    }) placeholderValue;

    @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    local: string = "";
    localPlaceholder: string = "";

    inputEl: any = null;
    autocomplete: any;

    created() {
        this.local = this.addressLocal;
        this.localPlaceholder = this.placeholderValue ? this.placeholderValue : "Enter a location";
    }

    @Watch("addressLocal")
    externalAddressChanged() {
        this.local = this.addressLocal;
    }

    @Watch("local")
    addressChanged() {
        this.$emit('addressChanged', this.local);
    }

    mounted(){
        this.inputEl = this.$refs.autocomplete;
        // Create the autocomplete object, restricting the search to geographical
        // location types.
        // this.autocomplete = new google.maps.places.Autocomplete(this.inputEl, { });

        // // When the user selects an address from the dropdown, populate the address
        // // fields in the form.
        // this.autocomplete.addListener('place_changed', (data) => {
        //     let place = this.autocomplete.getPlace();
        //     this.$emit('update:modelValue', place);
        // });
        
        // navigator.geolocation.getCurrentPosition((position) => {
        //     var geolocation = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude
        //     };
        //     var circle = new google.maps.Circle({
        //         center: geolocation,
        //         radius: position.coords.accuracy
        //     });
        //     this.autocomplete.setBounds(circle.getBounds());
        // });

        setTimeout(() => {
            this.inputEl.setAttribute("autocomplete", "chrome-off");
        }, 300);
    }


    emitInput(text) {
        this.$emit('changeAddress', text.data)
    }

    deleteAddress(){
        this.$emit('deleteAddress')
    }

}
