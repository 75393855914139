
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getArts, getArtsNumber, getStructurePath } from '@/utils';
import * as OM from '@/model';

@Options({})
export default class LocationStructure extends Vue {

    @Prop({
        default: () => new OM.ExhibitionSectionVM()
    }) section: OM.ExhibitionSectionVM;

    @Prop({
        default: () => []
    }) sections: OM.ExhibitionSectionVM[];

    @Prop({
        default: () => new OM.GetExhibitionVm()
    }) mostra: OM.GetExhibitionVm;

    @Prop({ 
        default: 0
    }) childrenCount: number;
    localChildrenCount: number = 0;

    created() {
        this.localChildrenCount = this.childrenCount;
    }

    getArtsNumber(section: OM.ExhibitionSectionVM) {
        var ris = getArtsNumber(section, this.mostra.artList);
        return ris;
    }

    sectionPath(sectionId: string) {
        return getStructurePath(sectionId, this.sections);
    }

    openLocationAreaDetail(section: OM.ExhibitionSectionVM) {
        this.$emit('openLocationAreaDetail', section);
    }

}
