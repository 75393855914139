import store from '@/store';
import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'loggeduser layout',
            component: () => import("./views/loggeduser_layout.vue"),
            children: [
                {
                    path: '/exhibition/:exhibitionIdentifier',
                    name: 'exhibition',
                    component: () => import("./views/exhibition.vue"),
                    meta: { hideFooter: true }
                },
                {
                    path: '/exhibition-item/:pin',
                    name: 'exhibition item',
                    component: () => import("./views/exhibitionItem.vue"),
                },
                {
                    path: '/exhibition-item/search/:searchText',
                    name: 'exhibition items search results',
                    component: () => import("./views/searchByTitleResults.vue"),
                },
                {
                    path: '/location/:locationIdentifier',
                    name: 'location',
                    component: () => import("./views/location.vue"),
                },
                {
                    path: '/author/:authorIdentifier',
                    name: 'author',
                    component: () => import("./views/author.vue"),
                    meta: { backButton: true }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import("./views/profile.vue"),
                },
                {
                    path: '/personal-info/:identifier',
                    name: 'personal info',
                    component: () => import("./views/personalInfo.vue"),
                },
                {
                    path: '/my-favorites',
                    name: 'favorites',
                    component: () => import("./views/favorites.vue"),
                },
                {
                    path: '/my-locations',
                    name: 'saved locations',
                    component: () => import("./views/savedLocations.vue"),
                },
                {
                    path: '/purchase-history',
                    name: 'purchase history',
                    component: () => import("./views/purchaseHistory.vue"),
                },
                {
                    path: '/exhibition-item-detail/:exhibitionItemIdentifier',
                    name: 'exhibition item detail',
                    component: () => import("./views/favoriteDetail.vue"),
                    meta: { backButton: true }
                }
            ]
        },
    ];
};