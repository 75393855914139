
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Options({})
export default class ModalContainer extends Vue {

    exhibition: OM.GetExhibitionVm = store.state.exhibition;

    created() {
        this.exhibition = StorageServices.getExhibition();
    }

}
