
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CustomerClient, StripeClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import ProgressBar from '@/components/progressBar.vue';
import * as OM from '@/model';

@Options({
    components: {
        ProgressBar
    }
})
export default class PatronageDonationModal extends Vue {

    @Prop({
        default: () => new OM.PatronageVM()
    }) patronage: OM.PatronageVM;

    @Prop() exhibitionItemTitle: string;
    @Prop() exhibitionItemImageUrl: string;
    @Prop() callback: any;

    showDonatorName: boolean = true;
    amount: number = 10;
    paymentId: string = "";

    customer: OM.Customer = new OM.Customer();
    step: number = 0;

    created() {
        var loggedUser = StorageServices.getLoggedUser();
        CustomerClient.getById(loggedUser.identifier)
        .then(x => {
            this.customer = x;
        })
    }

    get stringAmount() {
        return "€ " + this.amount.toString();
    }

    intentCb() {
        return StripeClient.createIntent(this.amount)
        .then(x => {
            this.paymentId = x.paymentId;
            return x.clientSecret;
        });
    }

    onPayComplete() {
        var model = new OM.ConfirmPatronagePaymentVM();
        model.patronage.identifier = this.patronage.identifier;
        model.patronage.text = this.exhibitionItemTitle;
        model.amount = this.amount;
        model.paymentId = this.paymentId;
        model.showDonatorName = this.showDonatorName;

        model.user = new OM.TextIdentifier();
        model.user.identifier = this.customer.identifier;
        model.user.text = this.customer.personalData.completeName;

        StripeClient.confirmPatronagePayment(model)
        .then(x => {
            this.step++;
        })
    }

}
