import { createApp } from 'vue';
import { LocalizationServices } from './services/LocalizationServices';
// import { UrlServices } from './services/UrlServices';
import App from './App.vue';
import router from './router';
import store from './store';
import modalPlugin from './plugins/modalPlugin';
import MultiRangeSlider from 'multi-range-slider-vue/MultiRangeSlider.vue'
import excel from 'vue-excel-xlsx';
import FTS from '@/filters';
import * as DIR from '@/directives';

import 'swiper/swiper-bundle.min.css';

import './utils';
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';
import "@/assets/css/font.css";
import "@/assets/css/animation.css";
import "@/assets/css/transition.css";
import "@/assets/css/hamburger.css";
import "@/assets/css/sliderRange.css";
import 'bootstrap/js/src/collapse.js';
import { debugAnalytics, platform } from './config';
// import { initFirebase, trackPageView } from './services/Firebase/FirebaseServices';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .use(excel)
    .component("MultiRangeSlider", MultiRangeSlider)
    .directive('date', DIR.date)
    .directive('autofocus', DIR.autofocus)
    .directive('language', DIR.language)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective);

app.config.globalProperties.$filters = FTS;
app.config.globalProperties.$localizationService = LocalizationServices;
// app.config.globalProperties.$urlServices = UrlServices;

// if(platform && !debugAnalytics)
//     initFirebase();
if(!platform && debugAnalytics)
    console.error("Piattaforma non settata! Se è un debug non è un problema, se no uno forse c'è")

router.beforeEach((to, from, next) => {
	store.state.backButton = <boolean>to.meta.backButton;
	next();
});

//questo aggiunge automaticamente ogni componente nella cartella 'components'
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

var body;
document.addEventListener("deviceready", () => {
    body = document.querySelector('div.body');
    
    if(StatusBar){
        StatusBar.overlaysWebView(false);
        StatusBar.backgroundColorByHexString("00274c");
    }
});

app.mount('#app');