import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'layout',
            component: () => import("./views/layout.vue"),
            children: [
                {
                    path: '/',
                    name: 'demo',
                    component: () => import("./views/demoPage.vue")
                },  
                {
                    path: '/welcome/:exhibitionIdentifier',
                    name: 'welcome',
                    component: () => import("./views/welcome.vue")
                },
                {
                    path: '/login',
                    name: 'login',
                    component: () => import("./views/login.vue")
                },  
                {
                    path: '/register',
                    name: 'register',
                    component: () => import("./views/register.vue")
                },
                {
                    path: '/reset-password',
                    name: 'reset password',
                    component: () => import("./views/resetPassword.vue")
                }
            ],
        },
    ];
};