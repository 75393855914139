import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-group mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_modal_container = _resolveComponent("modal-container")

  return (_openBlock(), _createBlock(_component_modal_container, null, {
    default: _withCtx(() => [
      (_ctx.itemType == 'Exhibition')
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "app_Would you like to leave a comment on this exhibition?",
            text: "Would you like to leave a comment on this exhibition?",
            class: "title white text-center d-block px-3"
          }))
        : _createCommentVNode("", true),
      (_ctx.itemType == 'ExhibitionItem')
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "app_Would you like to leave a comment on this item?",
            text: "Would you like to leave a comment on this item?",
            class: "title white text-center d-block px-3"
          }))
        : _createCommentVNode("", true),
      (_ctx.itemType == 'Author')
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 2,
            localizedKey: "app_Would you like to leave a comment on this author?",
            text: "Would you like to leave a comment on this author?",
            class: "title white text-center d-block px-3"
          }))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Comment",
          text: "Comment",
          class: "input_title"
        }),
        _withDirectives(_createVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.comment = $event))
        }, null, 512), [
          [_vModelText, _ctx.comment]
        ])
      ]),
      _createVNode("button", {
        class: "black mt-4",
        disabled: !_ctx.canSend,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveComment()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Send",
          text: "Send",
          class: "text-uppercase"
        })
      ], 8, ["disabled"])
    ]),
    _: 1
  }))
}