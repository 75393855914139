
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import { LocalizationClient, UtilityClient } from './services/services';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import InfoModal from './components/modals/infoModal.vue';

@Options({
    components: {
        SpinnerContainer,
    }
})
export default class App extends Vue {

    created() {
        store.state.loggedUser = StorageServices.getLoggedUser();

        let mostra = StorageServices.getExhibition();
        if(mostra) {
            store.state.exhibition = mostra;
        }

        let selectedLanguage = StorageServices.getLanguage();
        if(selectedLanguage) {
            store.state.selectedLanguage = selectedLanguage;
        }
        else {
            StorageServices.setLanguage("en-US");
            store.state.selectedLanguage = "en-US";
        }

        store.state.closeAllModalFunction = () => {
            this.$opModal.closeAll();
        }

        this.loadConfig();
    }

    loadConfig(firstTime: boolean = true) {
        Promise.all([
            LocalizationClient.getAll(!firstTime),
            LocalizationClient.getEnabledLanguages(!firstTime),
            UtilityClient.setLanguage(store.state.selectedLanguage)
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
        }).catch( ex => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: this.$localizationService.getLocalizedValue("app_QualcosaEAndatoStortoDuranteIlCaricamento", "Qualcosa è andato storto durante il caricamento!"),
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                deny: this.$localizationService.getLocalizedValue("app_Esci", "Esci"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.loadConfig(false);
                },
                denyCb: this.$opModal.closeLast()
            })
        })
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}
