
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

@Options({})
export default class SwiperSlides extends Vue {
    
    @Prop({
        type: Number,
        default: 0
    }) slideIndex: number;

    @Prop({
        default: true
    }) showPagination: boolean;
    canPaginate: boolean = true;
    
    @Prop({
        default: false
    }) autoHeight: boolean;

    @Prop({
        default: false
    }) arrow: boolean;

    @Prop({
        default: false
    }) freeMode: boolean;

    @Prop({
        default: false
    }) loop: boolean;

    @Prop({
        default: false
    }) autoplay: boolean;

    randomString: string = "";
    swiper: any;

    created() {
        this.randomString = (Math.random() * (9999 - 1000) + 1000).toString();
    }

    mounted() {
        this.$nextTick( () => {
            let paginationEl = <any>this.$refs.swiperPagination;
            this.swiper = new Swiper(<any>this.$refs.swiperContainer, {
                pagination: {
                    el: paginationEl,
                    type: 'bullets',
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: <any>this.$refs.swiper_next,
                    prevEl: <any>this.$refs.swiper_prev,
                },
                freeMode: this.freeMode,
                slidesPerView: 'auto',
                autoHeight: this.autoHeight,
                direction: 'horizontal',
                initialSlide: this.slideIndex,
                speed: 1000,
                spaceBetween: 20,
                on: {
                    slideChange: this.onSlideChange
                },
                loop: this.loop
            });
    
            if(this.swiper.slides.length == 1){
                this.canPaginate = false;
            }

            if(this.autoplay) {
                setInterval(() => {
                    this.swiper.slideNext();
                }, 2000)
            }
        })
    }
    @Watch('slideIndex')
    onSlideIndexChange(next, prev){
        this.swiper.slideTo(next);
    }

    onSlideChange(){
        if(this.swiper)
            this.$emit('slidechange', this.swiper.activeIndex);
    }
}

