
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class SelectedLanguageFlag extends Vue {

    @Prop() language: string;
    flagUrl: string = "/img/flags/";

    created() {
        this.flagUrl = this.flagUrl + this.language + ".svg";
    }

}
