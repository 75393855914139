
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getArts, getArtsNumber, getStructurePath, checkIfHasLocalizedValue } from '@/utils';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class LocationAreaDetailModal extends Vue {

    @Prop({ 
        default: () => new OM.GetExhibitionVm()
    }) mostra: OM.GetExhibitionVm;
    @Prop({ 
        default: () => new OM.ExhibitionSectionVM()
    }) section: OM.ExhibitionSectionVM;
    @Prop({ 
        default: () => []
    }) items: OM.ExhibitionItemResumeVm[];

    @Prop() callback: any;

    showMore: boolean = false;
    needLessButton: boolean = false;
    description: OM.LocalizedValue = new OM.LocalizedValue();
    loaded: boolean = false;

    showArtworksOrganized: boolean = true;

    created() {
        this.$nextTick(() => {
            let exhibitionDescriptionEl = <HTMLElement>this.$refs.mostraDescription;
            let windowTreshold = window.innerHeight * 19 / 100;
            if(parseFloat(getComputedStyle(exhibitionDescriptionEl).height) > windowTreshold){
                this.showMore = true;
                this.needLessButton = true;

                this.loaded = true;
            }
        })
    }

    get showDescription() {
        return checkIfHasLocalizedValue(this.section.description);
    }

    openLocationAreaDetail(section: OM.ExhibitionSectionVM) {
        var exhibitionItems = getArts(section, this.mostra.artList, this.mostra.exhibitionItems);

        this.$opModal.show(LocationAreaDetailModal, {
            mostra: this.mostra,
            section: section,
            items: exhibitionItems,
            callback: (pin: string) => {
                this.searchForOpera(pin);
            }
        })
    }

    sectionPath(sectionId: string) {
        return getStructurePath(sectionId, this.section.children);
    }

    getArtsNumber(sectionId: string) {
        var section = this.section.children.find( x => x.identifier == sectionId);
        return getArtsNumber(section, this.mostra.artList);
    }

    searchForOpera(pin: string) {
        this.callback(pin);
    }
    
}
