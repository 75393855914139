import router from "./router";
import route from "./router";
import store from "./store";
import * as OM from "./model";
import { LocalizationServices } from "./services/LocalizationServices";

export function routerReplaceAndPush(replaceUrl: string, pushUrl: string){
    router.replace(replaceUrl)
    .then( () => {
        router.push(pushUrl);
    }).catch( () => {
        console.error("router replace error!");
    })
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized)
        localized = new OM.LocalizedValue();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
} 

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function removeBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "hidden";
}
export function addBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "auto";
}

export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function getFileFromUri(uri: string): Promise<File>{
    if(uri.indexOf('://') === -1) 
        uri = "file://" + uri;

    let prom = new Promise<File>((resolve, reject) => {
        (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
            fileEntry.file((resFile) => {
                resolve(resFile);
            });
        }, err => {
            console.log(err)
        })
    });

    return prom;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if(!file){
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        if(!window.cordova){
            var url = URL.createObjectURL(file);
            resolve(url);
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function getStructurePath(sectionIdentifier: string, sections : OM.ExhibitionSectionVM[]) : string {

    var result = "";

    if(sections.length == 0)
        return result;

    var section = sections.find( x => x.identifier == sectionIdentifier);
    if(section != null)
        return LocalizationServices.getTextFromValues(section.name.values);

    section = sections.find( x => x.childrenIds.indexOf(sectionIdentifier));

    if(section == null)
        return result

    result = LocalizationServices.getTextFromValues(section.name.values);

    result += " - " + getStructurePath(sectionIdentifier, section.children);

    return result;
}

export function getArts(section : OM.ExhibitionSectionVM, exhibitionItems: { [key: string]: OM.ExhibitionItemSummary; }, items: OM.ExhibitionItemResumeVm[] ) : OM.ExhibitionItemResumeVm[] {
    
    var arts = Object.keys(exhibitionItems).map( x => {
        return exhibitionItems[x];
    })

    var artsFiltered = arts.filter( x => {
        if(section.identifier == x.sectionIdentifier || section.childrenIds.indexOf(x.sectionIdentifier) != -1)
            return x;
    }).map( x => x.identifier);

    // var result = [];
    // artsFiltered.forEach(element => {
    //     result.push(items.filter(x => x.identifier == element));
    // });

    var result = items.filter( x => artsFiltered.indexOf(x.identifier) != -1);
    
    return result;
}

export function getArtsNumber(section : OM.ExhibitionSectionVM, exhibitionItems: { [key: string]: OM.ExhibitionItemSummary; }) : number {
    
    var arts = Object.keys(exhibitionItems).map( x => {
        return exhibitionItems[x];
    })

    // var result = arts.filter( x => {
    //     if(section.identifier == x.sectionIdentifier || section.childrenIds.indexOf(x.sectionIdentifier) != -1)
    //         return x;
    // });

    var artsFiltered = arts.filter( x => {
        if(section.identifier == x.sectionIdentifier || section.childrenIds.indexOf(x.sectionIdentifier) != -1)
            return x;
    }).map( x => x.identifier);

    console.log(section);

    return artsFiltered.length;
}

export function getStructureMap(sections : OM.ExhibitionSectionVM[]) : string[] {

    var result = []

    if(sections.length == 0)
        return result;

    var toAdd = sections.filter( x => x.mapUrl).map( x => x.mapUrl);

    result.push(...toAdd);

    sections.forEach( section => {
        result.push(...getStructureMap(section.children));
    });

    return result;
}

export function checkIfHasLocalizedValue(item: OM.LocalizedValue) : boolean {

    var userLanguage = LocalizationServices.GetLanguage();
    var keys = Object.keys(item.values);
    var ris = "";

    if(keys.length == 0){
        return false;
    }

    if(keys.indexOf(userLanguage) == -1){
        var key = keys[0];
        userLanguage = key;
    }

    if(!item.values[userLanguage]) {
        var tempKey = keys.filter( x => x != userLanguage);
        userLanguage = tempKey[0];
    }

    ris = item.values[userLanguage];
    
    if(ris)
        return true;
    else
        return false;

}