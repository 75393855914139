
import { Options, Vue } from 'vue-class-component';
import { LocalizationServices } from '@/services/LocalizationServices';
import { ShareInteractionClient } from '@/services/services';
import { siteUrl } from '@/config';
import ModalContainer from '@/components/modalContainer.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        ModalContainer
    }
})
export default class ShareModal extends Vue {

    exhibitionIdentifier: string = store.state.exhibition.identifier;
    language: string = "";

    created() {
        this.language = LocalizationServices.GetLanguage();
    }

    get textToShare() {
        var exhibitionTitle = store.state.exhibition.title.values[this.language];
        return this.$localizationService.getLocalizedValue("app_TextToShare{{exhibitionTitle}}", "I'm at the {{exhibitionTitle}} exhibition. Check this out for more info!", "", "", { exhibitionTitle: exhibitionTitle });
    }

    get facebookShareLink() {
        return 'https://facebook.com/sharer/sharer.php?u=' + siteUrl + '/Home/ExibitionDetail?exibitionId=' + this.exhibitionIdentifier;
    }
    get twitterShareLink() {
        return 'https://twitter.com/intent/tweet/?text=' + this.textToShare + '&amp;url=' + siteUrl + '/Home/ExibitionDetail?exibitionId=' + this.exhibitionIdentifier;
    }
    get telegramShareLink() {
        return 'https://telegram.me/share/url?text=' + this.textToShare + '&amp;url=' + siteUrl + '/Home/ExibitionDetail?exibitionId=' + this.exhibitionIdentifier;
    }

    saveShareInteration(platform: string, shareLink: string) {
        var model = new OM.ExhibitionShareInteractionVM();
        model.exhibitionIdentifier = store.state.exhibition.identifier;
        model.sharePlatform = platform;
        model.shareLink = shareLink;

        ShareInteractionClient.saveExhibitionInteraction(model, false)
        .then(x => {})
    }

}
