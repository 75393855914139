
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class OpeningTimesModal extends Vue {

    @Prop() openingTimes: OM.OpeningTime;;

    created() {
    }

}
