import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")

  return _withDirectives((_openBlock(), _createBlock("input", {
    type: "mail",
    value: _ctx.localValue,
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event.target.value)),
    readonly: _ctx.readonly,
    tabindex: _ctx.tabIndex
  }, null, 40, ["value", "readonly", "tabindex"])), [
    [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
  ])
}