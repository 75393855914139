
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EmotionInteractionClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class InteractionModal extends Vue {
    
    @Prop() exhibitionItemId: string;
    @Prop() exhibitionItemPin: string;
    @Prop() imageUrl: string;

    emotions: string[] = [];

    created() {
        EmotionInteractionClient.getEmotions()
        .then(x => {
            this.emotions = x;
        })
    }

    vote(emotionSelected: string) {
        var model = new OM.ArtEmotionInteractionVM();
        model.exhibitionIdentifier = store.state.exhibition.identifier;
        model.artIdentifier = this.exhibitionItemId;
        model.emotion = emotionSelected;

        EmotionInteractionClient.saveArtInteraction(model, false)
        .then(x => {
            StorageServices.addOperaVotata(store.state.exhibition.identifier, this.exhibitionItemPin);
            this.$opModal.closeLast();
        })
    }
}
