
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class PatronageProgramModal extends Vue {

    @Prop({
        default: () => new OM.LocalizedValue()
    }) description: OM.LocalizedValue;

    created() {}

}
