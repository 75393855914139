
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SwiperSlides from '@/components/swiperSlides.vue';
import { nextTick } from 'vue';

@Options({
    components: {
        SwiperSlides
    }
})
export default class SwiperGalleryModal extends Vue {

    @Prop() isVideo: boolean;
    @Prop() gallery: string[];

    @Prop({
        default: 0
    }) index: number;
    localIndex: number = 0;

    loaded: boolean = false;

    created() {
        this.localIndex = this.index;
        this.$nextTick(() => {
            this.loaded = true;
        })
    }

    getYoutubeEmbedUrl(videoUrl: string){
        var part1 = videoUrl.substring(0, 23);
        var part2 = videoUrl.substring(32, videoUrl.length);
        var ris = part1 + '/embed/' + part2;
        return ris;
    }
    
}
