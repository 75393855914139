import * as OM from '@/model';
import * as VM from '@/viewModel';
import { CommonServices } from './CommonServices';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            let ris: any;
            try{
                ris = JSON.parse(data);
            } catch(ex){
                ris = data;
            }
            return ris;
        } else {
            return null;
        }
    }
    
    getMainExhibition() {
        return this.readFromLocalStorage<string>('mainExhibitionIdentifier');
    }
    setMainExhibition(mainExhibitionIdentifier: string) {
        window.localStorage.setItem('mainExhibitionIdentifier', JSON.stringify(mainExhibitionIdentifier));
    }

    getExhibition() {
        return this.readFromLocalStorage<OM.GetExhibitionVm>('exibition');
    }
    setExhibition(response: OM.GetExhibitionVm) {
        window.localStorage.setItem('exibition', JSON.stringify(response));
    }
    
    setAuthToken(token: string) {
        return CommonServices.setAuthToken(token);
    }
    getAuthToken(): string {
        return window.localStorage.getItem('authtoken');
    }
    
    getLastExhibitionKey() {
        return this.readFromLocalStorage<string>('lastExhibitionKey');
    }
    setLastExhibitionKey(exibitionKey: string) {
        window.localStorage.setItem('lastExhibitionKey', JSON.stringify(exibitionKey));
    }
    
    getOpereVotate(exibitionKey: string) {
        let ris = this.readFromLocalStorage<string[]>('votate' + exibitionKey);
        if(!ris)
            ris = [];
        return ris;
    }
    addOperaVotata(exibitionKey: string, operaVotata: string) {
        let votate = this.getOpereVotate(exibitionKey);
        votate.push(operaVotata);
        window.localStorage.setItem('votate' + exibitionKey, JSON.stringify(votate));
    }

    setLanguage(language: string) {
        window.localStorage.setItem('currentLanguage', JSON.stringify(language));
    }
    getLanguage() {
        return this.readFromLocalStorage<string>('currentLanguage');
    }

    getHideNewsletterModal() {
        return this.readFromLocalStorage<boolean>('hideNewsLetterModal');
    }
    setHideNewsletterModal(value: boolean) {
        window.localStorage.setItem('hideNewsLetterModal', JSON.stringify(value));
    }

    getFeedbackNotes() {
        return this.readFromLocalStorage<Array<string>>('hideFeedbackNoteModal');
    }
    setFeedbackNotes(artCode: string) {
        var arr = this.getFeedbackNotes();
        if(!arr)
            arr = [];
        arr.push(artCode);
        window.localStorage.setItem('feedbackNotes', JSON.stringify(arr));
    }

    getLoggedUser() {
        var loggedUser = this.readFromLocalStorage<OM.BaseApiLoginResponse>('loggedUser');

        if(!loggedUser){
            loggedUser = new OM.BaseApiLoginResponse();
            StorageServices.setLoggedUser(loggedUser);
        }

        return loggedUser;
    }
    
    setLoggedUser(response: OM.BaseApiLoginResponse) {
        window.localStorage.setItem('loggedUser', JSON.stringify(response));
    }


}

export let StorageServices = new _StorageServices();