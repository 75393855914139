
import { Options, Vue } from 'vue-class-component';
import { LocalizationServices } from '@/services/LocalizationServices';
import ModalContainer from '@/components/modalContainer.vue';
import SelectedLanguageFlag from '@/components/selectedLanguageFlag.vue';
import { UtilityClient } from '@/services/services';

@Options({
    components: {
        ModalContainer,
        SelectedLanguageFlag
    }
})
export default class LanguageModal extends Vue {

    languages: string[] = ["en-US", "it-IT"];

    created() {
        // console.log(LocalizationServices.GetLanguage());
    }

    setLanguage(value: string) {
        UtilityClient.setLanguage(value)
        .then(x => {
            LocalizationServices.SetLanguage(value);
            this.$opModal.closeLast();
        })
    }
}
