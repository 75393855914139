import { createStore } from 'vuex';
import * as OM from '@/model';
import * as VM from '@/viewModel';

let store = {
    state: {
        showSpinner: 0,
        loggedUser: new OM.BaseApiLoginResponse(),
        appConfiguration: new VM.AppConfiguration(),
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[],
            extendedCountries: <OM.ExtendedCountry[]>[],
            enabledLanguages: <string[]>[]
        },
        backButton: false,
        exhibition: new OM.GetExhibitionVm(),
        selectedLanguage: "",
        hideNewsletterModal: false,
        feedbackNotes: [],
        notification: null,
        notificationFunction: () => null,
        closeAllModalFunction: () => null
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

export default createStore(store);