import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/staff',
            name: 'layout staff',
            component: () => import("./views/layoutStaff.vue"),
            children: [
                {
                    path: '/staff/exhibitions',
                    name: 'staff exhibitions',
                    component: () => import("./views/exhibitions.vue"),
                },
            ],
        },
    ];
};