
import { mixins, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { BaseFormField } from './mixins/baseFormField';
import CheckboxField from './input/checkboxField.vue';
import GoogleAutocomplete from './input/googleAutocomplete.vue';
import inputDate from './input/inputDate.vue';
import InputFile from './input/inputFile.vue';
import inputMail from './input/inputMail.vue';
import inputNumber from './input/inputNumber.vue';
import inputPassword from './input/inputPassword.vue';
import inputSearch from './input/inputSearch.vue';
import inputText from './input/inputText.vue';
import inputTime from './input/inputTime.vue';
import LocalizedInput from './input/localizedInput.vue';
import LocalizedTextArea from './input/localizedTextArea.vue';
import SelectField from './input/selectField.vue';
import * as OM from "@/model";
import * as VM from "@/viewModel";
import inputCodeConfirmation from './input/inputCodeConfirmation.vue';
import inputRange from './input/inputRange.vue';

@Options({
    components: {
        SelectField,
        CheckboxField,
        LocalizedInput,
        GoogleAutocomplete,
        LocalizedTextArea
    }
})
export default class FormField extends mixins(BaseFormField) {
    googlePlace = null;

    @Watch('googlePlace')
    onGooglePlaceChange(next, prev) {
        this.$emit("update:modelValue", this.getAddressFromGooglePlace(next));
    }

    deleteAddress() {
        this.$emit("update:modelValue", new OM.Address());
    }

    get inputComponent() {
        if (this.type == "text")
            return inputText;
        else if (this.type == "date")
            return inputDate;
        else if (this.type == "email")
            return inputMail;
        else if (this.type == "password")
            return inputPassword;
        else if (this.type == "search")
            return inputSearch;
        else if (this.type == "number")
            return inputNumber;
        else if (this.type == "file")
            return InputFile;
        else if (this.type == "time")
            return inputTime;
        else if (this.type == "CodeConfirmation")
            return inputCodeConfirmation;
        else if (this.type == "range")
            return inputRange;

        return null;
    }

    getAddressFromGooglePlace(next): OM.Address {
        var temp = new OM.Address();

        temp.typedAddress = next.formatted_address;
        temp.completeAddress = next.formatted_address;
        temp.latitude = next.geometry.location.lat();
        temp.longitude = next.geometry.location.lng();
        temp.name = next.name;
        temp.streetNumber = this.returnValue(next.address_components, "street_number");
        temp.route = this.returnValue(next.address_components, "route");
        temp.country = this.returnValue(next.address_components, "country");
        temp.countryCode = this.returnValue(next.address_components, "country", true);
        temp.postalCode = this.returnValue(next.address_components, "postal_code");

        temp.locality = this.returnValue(next.address_components, "locality");

        if (!temp.locality)
            temp.locality = this.returnValue(next.address_components, "administrative_area_level_3");

        temp.province = this.returnValue(next.address_components, "administrative_area_level_2", true);
        temp.region = this.returnValue(next.address_components, "administrative_area_level_1");

        return temp;
    }

    returnValue(array: any[], key: string, shortName: boolean = false) {
        var object = array.find(x => {
            return x.types.find(y => {
                return y == key
            })
        });

        if (object) {
            if (!shortName)
                return object.long_name;
            return object.short_name;
        }
        return "";
    }

    updatePrefix(selectedPrefix: string) {
        this.$emit("changedPrefix", selectedPrefix);
    }
}
