
import { Options, Vue } from 'vue-class-component';
import ShareModal from '@/components/modals/shareModal.vue';

@Options({})
export default class Share extends Vue {

    created() {}

    shareExhibition() {
        this.$opModal.show(ShareModal, {});
    }

}
