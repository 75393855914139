import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-2"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_get_day_of_week = _resolveComponent("get-day-of-week")
  const _component_modal_container = _resolveComponent("modal-container")

  return (_openBlock(), _createBlock(_component_modal_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_localized_text, {
        localizedKey: "app_OpeningTimes",
        text: "Opening Times",
        class: "title white text-center d-block px-3 py-3 mb-4"
      }),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.openingTimes.workPeriods, (day, index) => {
        return (_openBlock(), _createBlock("div", {
          key: index,
          class: "mb-2 text-center"
        }, [
          _createVNode(_component_get_day_of_week, {
            value: day.dayOfWeek,
            spanClass: 'fs_14 orange'
          }, null, 8, ["value"]),
          _createVNode("div", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(day.openingPeriods, (openingPeriod, i) => {
              return (_openBlock(), _createBlock("span", {
                class: "descriptionGrey",
                key: i
              }, [
                _createTextVNode(_toDisplayString(openingPeriod.open.substring(0, 5)) + " - " + _toDisplayString(openingPeriod.close.substring(0, 5)) + " ", 1),
                (i == 0 && day.openingPeriods.length > 1)
                  ? (_openBlock(), _createBlock("span", _hoisted_1, "/"))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          (day.openingPeriods.length == 0)
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode(_component_localized_text, {
                  localizedKey: "app_chiuso",
                  text: "Closed",
                  class: "descriptionGrey"
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}