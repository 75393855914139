
import { Options, Vue } from 'vue-class-component';
import { StripeClient } from '@/services/Services';
import { stripeKey } from '@/config';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class StripeForm extends Vue {

    @Prop() userIdentifier: string;
    @Prop() userName: string;
    @Prop() intentCb: () => Promise<string>;

    paid: boolean = false;
    paying: boolean = false;

    stripeKey = stripeKey;

    stripe: any;
    cardNumber: any;
    cardExpiry: any;
    cardCvc: any;
    cardholderName: string = "";

    clientSecret: string = "";

    cardholderPlaceholder: string = "";

    created() {
        this.cardholderPlaceholder = this.$localizationService.getLocalizedValue("app_NameSurname", "Name & Surname"),

        this.$nextTick(() => {
            this.mountStripe();
        });

        this.stripe = Stripe(this.stripeKey);
        this.intentCb()
        .then(x => {
            this.clientSecret = x;
        })
    }

    mountStripe(){
        var elements = this.stripe.elements();
        var style = {
            base: {
                color: "#ffffff",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
                border: "1px solid white"
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        };
        // this.card = elements.create("card", { hidePostalCode: true, style: style });
        // this.card.mount(this.$refs.cardelement);
        this.cardNumber = elements.create('cardNumber', {
            style: style
        });
        this.cardNumber.mount(this.$refs.cardnumber);

        this.cardExpiry = elements.create('cardExpiry', {
            style: style
        });
        this.cardExpiry.mount(this.$refs.cardexpiry);
        
        this.cardCvc = elements.create('cardCvc', {
            style: style
        });
        this.cardCvc.mount(this.$refs.cardcvc);

        this.cardNumber.addEventListener('change', ({error}) => {
            const displayError = <HTMLElement>this.$refs.carderrors;
            if (error) {
                displayError.textContent = error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    get canPay(){
        return !!this.clientSecret && this.cardholderName.trim();
    }

    pay() {
        this.paying = true;
	
        this.stripe.createToken(this.cardNumber)
        .then(result => {
            if (result.error) {
                // Inform the user if there was an error.
                var errorElement = <HTMLElement>this.$refs.carderrors;
                errorElement.textContent = result.error.message;
                this.paying = false;
            } else {
                this.confirmCardPayment({
                    card: {
                        token: result.token.id
                    },
                    billing_details: {
                        name: this.userName,
                    },
                })
            }
        })		
    }

    confirmCardPayment(payment_method){
        this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: payment_method,
        })
        .then(async (paymentResult) => {
            if(paymentResult.error){
                var errorElement = <HTMLElement>this.$refs.carderrors;
                errorElement.textContent = paymentResult.error.message;
                this.paying = false;
            } else {
                this.paying = false;
                this.$emit('complete', paymentResult.paymentIntent.id);
            }
        });
    }
}

