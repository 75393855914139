
export class LocaleKeyLabel {
    key: string;
    label: string;
    constructor(){
        this.key = "";
        this.label = "";
    }
}

export class LocalesObject {
    [key: string]: LocaleKeyLabel;
}

export class AppRole {
    public static readonly Admin = "admin";

    public static readonly Staff = "staff";
    
    public static readonly Customer = "customer";
    public static readonly Anonymous = "anonymous";

    public static readonly BaseUser = "baseUser";
}

export let GetEmotions = {
    0 : {
        "it-IT" : "Paura",
        "en-US" : "Fear"
    },
    1 : {
        "it-IT" : "Malinconia",
        "en-US" : "Meloncholy"
    },
    2 : {
        "it-IT" : "Felicità",
        "en-US" : "Happiness"
    },
    3 : {
        "it-IT" : "Sorpresa",
        "en-US" : "Surprise"
    },
    4 : {
        "it-IT" : "Ammirazione",
        "en-US" : "Admiration"
    },
    5 : {
        "it-IT" : "Divertimento",
        "en-US" : "Fun"
    },
    6 : {
        "it-IT" : "Ansia",
        "en-US" : "Anxiety"
    },
    7 : {
        "it-IT" : "Imbarazzo",
        "en-US" : "Embarrassment"
    },
    8 : {
        "it-IT" : "Noia",
        "en-US" : "Bore"
    },
    9 : {
        "it-IT" : "Tranquillità",
        "en-US" : "Calm"
    },
    10 : {
        "it-IT" : "Confusione",
        "en-US" : "Mess"
    },
    11 : {
        "it-IT" : "Estasi",
        "en-US" : "Ecstasy"
    },
    12 : {
        "it-IT" : "Eccitazione",
        "en-US" : "Excitement"
    },
    13 : {
        "it-IT" : "Nostalgia",
        "en-US" : "Nostalgia"
    },
    14 : {
        "it-IT" : "Sollievo",
        "en-US" : "Relief"
    },
    15 : {
        "it-IT" : "Romanticismo",
        "en-US" : "Romanticism"
    },
    16 : {
        "it-IT" : "Appagamento",
        "en-US" : "Contentment"
    },
    17 : {
        "it-IT" : "Desiderio sessuale",
        "en-US" : "Sexual desire"
    },
    18 : {
        "it-IT" : "Positività",
        "en-US" : "Positivity"
    },
}

export class AppConfiguration {
    momiPrivacyPolicyLink : string;
    momiTermsConditionsLink : string;

    constructor() 
    {
        this.momiPrivacyPolicyLink = "" ;
        this.momiTermsConditionsLink = "" ;
    }
}